import { useContext, useRef } from "react";
import { AssetManagerContext, useBeforeRender } from "react-babylonjs";

const totalCount = 1;

const ModelFallback = () => {
  const ref = useRef<any>(null);
  const context = useContext(AssetManagerContext);

  useBeforeRender(scene => {
    if (ref.current) {
      const dt = scene.getEngine().getDeltaTime();

      const rpm = 10;
      ref.current.rotation.x = Math.PI / 4;
      ref.current.rotation.y += (rpm / 60) * Math.PI * 2 * (dt / 1000);
    }
  });

  const event = context?.lastProgress?.eventData;

  return (
    <>
      <adtFullscreenUi name="ui">
        <rectangle name="rect" height="50px" width="150px">
          <rectangle>
            {event !== undefined && (
              <textBlock
                text={`${event.totalCount - event.remainingCount}/${
                  event.totalCount
                }`}
                fontStyle="bold"
                fontSize={20}
                color="white"
              />
            )}
            {event === undefined && (
              <textBlock
                text={`0/${totalCount}`}
                fontStyle="bold"
                color="white"
              />
            )}
          </rectangle>
        </rectangle>
      </adtFullscreenUi>
      <box ref={ref} name="fallback" size={2} />
    </>
  );
};

export default ModelFallback;
