import styled from "@emotion/styled";
import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DownloadIcon from "../icons/DownloadIcon";
import HelpIcon from "../icons/HelpIcon";
import { COUNTRY, getLocales } from "../utils";

const HeaderContainer = styled("header", {
  label: "HeaderContainer"
})(() => {
  return {
    padding: 16,
    position: "absolute",
    width: "100%"
  };
});

const HeaderTextContainer = styled("div", {
  label: "HeaderTextContainer"
})(() => {
  return {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 30
  };
});

const DescriptionContainer = styled("div", {
  label: "DescriptionContainer"
})(() => {
  return {
    display: "flex",
    flexDirection: "column",
    gap: 10
  };
});

const DescriptionWrapper = styled("div", {
  label: "DescriptionWrapper"
})(() => {
  return {
    textAlign: "right"
  };
});

const DescriptionSubject = styled("span", {
  label: "DescriptionSubject"
})(() => {
  return {
    fontWeight: "bold",
    marginRight: 10,
    color: "white"
  };
});

const DescriptionText = styled("span", {
  label: "DescriptionText"
})(() => {
  return {
    color: "lightgray"
  };
});

const Download = styled("div", {
  label: "Download"
})(() => {
  return {
    cursor: "pointer"
  };
});

const Help = styled("div", {
  label: "Help"
})(() => {
  return {
    cursor: "help"
  };
});

const Header = () => {
  const [searchParams] = useSearchParams();
  const [helptextVisible, setHelptextVisible] = useState(false);

  const _baseurl = searchParams.get("baseurl") || "";
  const baseurl = window.atob(_baseurl);
  const filename = searchParams.get("filename") || "output";
  const country = (searchParams.get("country") || "en") as COUNTRY;

  const {
    stlViewerMove,
    stlViewerMoveDescription,
    stlViewerRotate,
    stlViewerRotateDescription,
    stlViewerZoom,
    stlViewerZoomDescription
  } = getLocales(country);

  const onDownload = async () => {
    const a = document.createElement("a");

    const controller = new AbortController();
    const { signal } = controller;

    a.href = await fetch(baseurl, { signal })
      .then(async res => {
        const reader = res?.body?.getReader();

        if (!reader) return;
        const chunks = [];
        while (true) {
          const { done, value } = await reader.read();

          if (done) {
            break;
          }

          chunks.push(value);
        }

        const blob = new Blob(chunks);
        return blob;
      })
      .then(blob => window.URL.createObjectURL(blob!));
    a.download = `${filename}.stl`;
    a.click();
  };

  const onVisibleHelp = useCallback(() => setHelptextVisible(true), []);

  const onUnvisibleHelp = useCallback(() => setHelptextVisible(false), []);

  return (
    <HeaderContainer>
      <HeaderTextContainer>
        <Help onMouseOver={onVisibleHelp} onMouseOut={onUnvisibleHelp}>
          <HelpIcon />
        </Help>
        <Download onClick={onDownload}>
          <DownloadIcon />
        </Download>
      </HeaderTextContainer>
      {helptextVisible && (
        <DescriptionContainer>
          <DescriptionWrapper>
            <DescriptionSubject>{stlViewerRotate}</DescriptionSubject>
            <DescriptionText>{stlViewerRotateDescription}</DescriptionText>
          </DescriptionWrapper>
          <DescriptionWrapper>
            <DescriptionSubject>{stlViewerMove}</DescriptionSubject>
            <DescriptionText>{stlViewerMoveDescription}</DescriptionText>
          </DescriptionWrapper>
          <DescriptionWrapper>
            <DescriptionSubject>{stlViewerZoom}</DescriptionSubject>
            <DescriptionText>{stlViewerZoomDescription}</DescriptionText>
          </DescriptionWrapper>
        </DescriptionContainer>
      )}
    </HeaderContainer>
  );
};

export default Header;
