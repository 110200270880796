import en from "./locales/en.json";
import ko from "./locales/ko.json";
import eg from "./locales/eg.json";
import vi from "./locales/vi.json";
import ru from "./locales/ru.json";

interface LocaleKeys {
  stlViewerMove: string;
  stlViewerMoveDescription: string;
  stlViewerRotate: string;
  stlViewerRotateDescription: string;
  stlViewerZoom: string;
  stlViewerZoomDescription: string;
}

export type COUNTRY = "en" | "ko" | "eg" | "vi" | "ru";

type Locales = {
  [key in COUNTRY]: LocaleKeys;
};

const locales: Locales = {
  en,
  ko,
  eg,
  vi,
  ru
};

const getLocales = (country: COUNTRY): LocaleKeys => {
  return locales[country];
};

export { getLocales };
