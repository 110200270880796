import { Vector3 } from "@babylonjs/core";
import { Suspense } from "react";
import { AssetManagerContextProvider, Engine, Scene } from "react-babylonjs";
import { useSearchParams } from "react-router-dom";
import Model3D from "./Model3D";
import ModelFallback from "./ModelFallback";
import "babylonjs-loaders";
import "@babylonjs/inspector";

// https://minio.develop.vsmart00.com/clever/files/file/vatech/9df64229-3f04-41c4-b87e-21d095759cbb.stl?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=haruband%2F20220513%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220513T003758Z&X-Amz-Expires=43200&X-Amz-SignedHeaders=host&X-Amz-Signature=7d04e48efda0ea355b893c53138b28240d29c9bb12a5e222bbaf6ec0ec5c9f63

const Viewer = () => {
  const [searchParams] = useSearchParams();

  const _baseurl = searchParams.get("baseurl");

  if (!_baseurl) return <div>None Image</div>;
  const baseurl = window.atob(_baseurl);
  const filename = searchParams.get("filename") || "";
  const antialias = Boolean(searchParams.get("antialias")) || false;

  if (!baseurl) return <div>None Image</div>;

  return (
    <Engine antialias={antialias} adaptToDeviceRatio canvasId="mesh3d">
      <Scene>
        <arcRotateCamera
          name="camera0"
          alpha={Math.PI / 2}
          beta={Math.PI / 2}
          radius={9.0}
          target={Vector3.Zero()}
          minZ={0.001}
        />
        <hemisphericLight
          name="light0"
          intensity={0.7}
          direction={Vector3.Up()}
        />
        <AssetManagerContextProvider>
          <Suspense fallback={<ModelFallback />}>
            <Model3D baseurl={baseurl} filename={filename} />
          </Suspense>
        </AssetManagerContextProvider>
      </Scene>
    </Engine>
  );
};

export default Viewer;
