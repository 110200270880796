import { Vector3 } from "@babylonjs/core";
import { useMemo } from "react";
import { TaskType, useAssetManager } from "react-babylonjs";

interface Model3DProps {
  baseurl: string;
  filename: string;
}

const Model3D = (props: Model3DProps) => {
  const { baseurl, filename } = props;
  const assetManagerResult = useAssetManager([
    {
      taskType: TaskType.Mesh,
      rootUrl: baseurl,
      sceneFilename: filename,
      name: "model0"
    }
  ]);

  useMemo(() => {
    if (baseurl && filename) {
      const task: any = assetManagerResult.taskNameMap["model0"];
      task.loadedMeshes[0].position = new Vector3(0, 0, 0);
      task.loadedMeshes[0].scaling = new Vector3(1, 1, 1);
    }
  }, [assetManagerResult.taskNameMap, baseurl, filename]);

  return null;
};

export default Model3D;
